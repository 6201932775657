module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":["gatsby-remark-numbered-footnotes",{"resolve":"gatsby-remark-autolink-headers","options":{"icon":false,"removeAccents":true}},{"resolve":"gatsby-remark-images","options":{"maxWidth":1024,"linkImagesToOriginal":false,"withWebp":true,"tracedSVG":false}},"gatsby-remark-copy-linked-files",{"resolve":"gatsby-remark-prismjs","options":{"classPrefix":"language-","showLineNumbers":false}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-152680555-1","AW-661213510","G-DV6NWG4TE7"],"gtagConfig":{"anonymize_ip":false},"pluginConfig":{"head":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Wish To Go","short_name":"Wish To Go","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/wish-to-go-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"fb4bee53182bc27c754e9a3eebadf547"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
