export var PopupForm = function PopupForm() {
  // return (
  // 	<ScriptLoader>
  // 		<script async 
  // 			data-uid="89d66a0881" 
  // 			src="https://wish-to-go.ck.page/89d66a0881/index.js"
  // 		/>
  // 	</ScriptLoader>
  // )
  return null;
};
export var WorkWithUsForm = function WorkWithUsForm() {
  // return (
  // 	<iframe 
  // 		style= {{ width:"100%", height:"910px", overflow: 'hidden' }}
  // 		src="https://wish-to-go.ck.page/3452480e33"
  // 	/>
  // )
  return null;
};